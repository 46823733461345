/* Chat board */

.viewChatBoard {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.headerChatBoard {
    border-bottom: 1px solid #e8e8e8;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.textHeaderChatBoard {
    font-weight: bold;
    color: #203152;
    margin-left: 10px;
}

.viewListContentChat {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: scroll;
    padding-top: 10px;
    padding-bottom: 20px;
}

.viewListContentChat::-webkit-scrollbar-track {
    padding: 2px 0;
}

.viewListContentChat::-webkit-scrollbar {
    width: 6px;
}

.viewListContentChat::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #e8e8e8;
}

.viewBottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 50px;
    border-top: 1px solid #e8e8e8;
}

.icOpenGallery {
    width: 30px;
    height: 30px;
    margin-left: 10px;
}

.viewInputGallery {
    opacity: 0;
    position: absolute;
    z-index: -1;
    left: 10px;
    width: 30px;
}

.icOpenSticker {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-right: 5px;
}

.icSend {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-right: 5px;
}

.viewInput {
    flex: 1;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    border: 0px;
    height: 30px;
}

.viewInput:focus {
    outline: 0;
}

input::placeholder {
    color: rgb(199, 199, 199);
}

/* View item message */

.viewItemRight {
    width: 300px;
    height: auto;
    background-color: #e8e8e8;
    align-self: flex-end;
    margin-right: 20px;
    margin-top: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    color: #203152;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.viewItemRight2 {
    width: 300px;
    height: auto;
    background-color: #e8e8e8;
    align-self: flex-end;
    margin-right: 20px;
    margin-top: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    color: #203152;
    text-align: left;
}

.viewItemRight3 {
    width: 300px;
    height: auto;
    align-self: flex-end;
    margin-right: 20px;
    margin-top: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    text-align: left;
}

.viewWrapItemLeft {
    width: 300px;
    text-align: left;
    align-self: flex-start;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: -15px;
}

.viewWrapItemLeft2 {
    width: 300px;
    align-self: flex-start;
    text-align: left;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: -15px;
}

.viewWrapItemLeft3 {
    flex-direction: row;
    display: flex;
    width: 340px;
    margin-bottom: -15px;
}

.viewItemLeft {
    width: 300px;
    height: auto;
    background-color: #203152;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
}

.viewItemLeft2 {
    width: 300px;
    height: auto;
    background-color: #203152;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    color: white;
}

.viewItemLeft3 {
    width: 300px;
    height: auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    color: white;
}

.peerAvatarLeft {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    object-fit: cover;
    align-self: flex-end;
}

.viewPaddingLeft {
    width: 40px;
}

/* Item text */

.textContentItem {
    font-size: 14px;
    word-break: break-all;
}

.viewListContentChat div:last-child {
    margin-bottom: 10px;
}

/* Item image */

.imgItemRight {
    object-fit: cover;
    width: 300px;
    height: 300px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
}

.imgItemLeft {
    object-fit: cover;
    width: 300px;
    height: 300px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
}

.textTimeLeft {
    color: #808888;
    font-size: 12px;
    font-style: italic;
    margin-left: 50px;
}

/* Stickers */

.viewStickers {
    display: flex;
    border-top: 1px solid #e8e8e8;
    height: 100px;
    align-items: center;
    justify-content: space-around;
}

.imgSticker {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

/* Say hi */

.viewWrapSayHi {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.textSayHi {
    color: #808888;
    font-size: 14px;
}

.imgWaveHand {
    width: 40px;
    height: 40px;
    margin-left: 10px;
}
