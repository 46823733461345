body, html {
    height: 100%;
    
  }
  
  * {
    box-sizing: border-box;
  }
  
  
  .bg-image {
    /* The image used */
    
    background-image: url("../img/theme/login_bg.png");
  
    /* Add the blur effect */
    filter: blur(2px);
    -webkit-filter: blur(2px);
  
    /* Full height */
    height: 100%;
    
  
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  /* Position text in the middle of the page/image */
  .bg-text {
    /* background-color: rgb(0,0,0); Fallback color */
    /* background-color: rgba(0,0,0, 0.4); Black w/opacity/see-through */
    color: white;
    font-weight: bold;
    /* border: 3px solid #f1f1f1; */
    /* position: absolute; */
    top: 50%;
    left: 50%;
    transform: translate(10%, -110%);
    z-index: 2;
    width: 82%; 
    padding: 20px;
    /* text-align: center; */
  }