.box_result .result_comment h4 {
  font-weight: 600;
  white-space: nowrap;
  color: #365899;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.358;
  margin: 0;
  /*   border:dotted; */
}
.box_result .result_comment {
  display: block;
  overflow: hidden;
  padding: 0;
  border: dotted;
}

.box_result .result_comment .tools_comment {
  font-size: 12px;
  line-height: 1.358;
}
.box_result .result_comment .tools_comment a {
    color: #4267b2;
  cursor: pointer;
  text-decoration: none;
}
.box_result .result_comment .tools_comment span {
  color: #90949c;
}


.delete {
  opacity: 1;
cursor: default;
}

.delete:hover  {
  color:red;

  animation: shake 1.5s;
  
  animation-iteration-count: infinite;



  
}


@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }



.delete-overlay {
    transition: .5s ease;
    opacity: 1;
    position: absolute;
    top: 80%;
    left: 78%;
    transform: translate(21%, 22%);
    -ms-transform: translate(21%, 22%);
    text-align: center;
    zoom:85%;
  }

  
  .result_comment:hover .delete-overlay {
    opacity: 1;
  }


  


